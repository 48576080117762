<template>
  <div class="error">
    <div class="error__wrapper">
      <nuxt-img
        format="webp"
        src="/404.png"
        sizes="sm:100vw md:50vw lg:400px"
        placeholder
      />
      <h1>
        {{ props.error.statusCode }}
      </h1>
      <h3>
        <template v-if="props.error.statusCode === 404">
          {{ $t(`error.${props.error.statusCode}`) }}
        </template>
        <template v-else>
          {{ $t("error.other") }}
        </template>
      </h3>
      <p>
        <template v-if="props.error.statusCode === 404">
          {{ $t(`error.${props.error.statusCode}.message`) }}
        </template>
        <template v-else>
          {{ $t("error.other.message") }}
        </template>
      </p>
      <button @click="handleError">
        <template v-if="props.error.statusCode === 404">
          {{ $t(`error.${props.error.statusCode}.button`) }}
        </template>
        <template v-else>
          {{ $t("error.other.button") }}
        </template>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  error?: {
    statusCode?: string | number;
    url?: string;
    statusMessage?: string;
    message?: string;
    stack?: string;
  };
}

const props = defineProps<Props>();
const handleError = () => {
  if (props.error?.statusCode === 404) {
    clearError({ redirect: "/" });
  } else {
    location.href = "https://t.me/PayStoreTransfer_bot";
  }
};
</script>

<style lang="scss">
.error {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    max-width: 510px;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    @screen lg {
      padding: 2.5rem;
    }

    & img {
      width: 265px;
      height: 265px;
      margin: 0 auto;
    }

    & h1 {
      font-size: 3em;
      font-weight: 800;
    }

    & h3 {
      font-size: 2em;
      font-weight: 600;
    }

    & p {
      font-weight: 500;
      opacity: 0.75;
    }

    & button {
      background: #1a202c;
      border-radius: 12px;
      width: 100%;
      color: #ffffff;
      padding: 0.875rem 2rem;
      margin-top: 2.5rem;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }
  }
}
</style>
