import type { RouterConfig } from "nuxt/schema";

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => {
    const config = useRuntimeConfig();
    if (config.public.siteUrl === "https://ultpst.com") {
      return [
        {
          name: "home",
          path: "/",
          component: () =>
              import("~/pages/marketing/landings/pst-ultima.vue").then(
                  (r) => r.default || r,
              ),
          meta: { layout: "landing" }
        },
      ];
    } else {
      return null;
    }
  },
};
