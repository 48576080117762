export const appBuildAssetsDir = "/static/"

export const appRootId = "app"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=5.0"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"//cdn-4.convertexperiments.com/js/10042143-10042929.js"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=5.0"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null