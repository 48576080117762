// @ts-nocheck
export default defineNuxtRouteMiddleware((to, from) => {
  if (to.query && !!Object.entries(to.query).length) {
    console.log("to.query", to.query);
    let l = "";
    if (!import.meta.env.SSR) {
      l = location.host;
    } else {
      l = "pst.net";
    }
    const addr = l.split(".").slice(-2).join(".");
    const UTM = useCookie("utm", {
      domain: "." + addr,
      maxAge: 31556926,
      sameSite: "none",
      secure: true,
    });
    const headers = useRequestHeaders();
    const utmTagList = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
    ];
    let utmTemp = {};
    Object.entries(to.query).forEach((It: any) => {
      if (utmTagList.includes(It[0])) {
        utmTemp[It[0]] = It[1];
      }
    });
    if (headers && headers.referer) {
      const referer = headers.referer;
      if (referer) {
        utmTemp = Object.assign(utmTemp, referer);
      }
    }
    if (utmTemp) {
      utmTemp = Object.assign(utmTemp, {
        // domain: "." + addr,
        maxAge: 31556926,
        sameSite: "none",
        secure: true,
      });
    }
    UTM.value = utmTemp;
  }
});
