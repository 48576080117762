import { format } from "date-fns";
import { ru, es, fr, ja } from "date-fns/locale";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      getFormatDate: (date: Date | string, lang: string, template: string) => {
        if (lang === "ru") {
          return format(new Date(date), template || "d MMMM, yyyy", {
            locale: ru,
          });
        }
        if (lang === "es") {
          return format(new Date(date), template || "d MMMM, yyyy", {
            locale: es,
          });
        }
        if (lang === "fr") {
          return format(new Date(date), template || "d MMMM, yyyy", {
            locale: fr,
          });
        }
        if (lang === "ja") {
          return format(new Date(date), template || "d MMMM, yyyy", {
            locale: ja,
          });
        }
        if (lang === "en" || !lang) {
          return format(new Date(date), template || "d MMMM, yyyy");
        }
      },
    },
  };
});
