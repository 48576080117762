// @ts-nocheck
type EnvConfig = {
  amplitudeApiKey: string;
  amplitudeQueryPropertyPrefix: string;
  appUrl: string;
  apiUrl: string;
  contentApiUrl: string;
  contentApiKey: string;
  dashboardUrl: string;
  telegramSupport: string;
  skypeSupport: string;
  telegramChannelEn: string;
  telegramChannelRu: string;
  twitterChannelEn: string;
  twitterChannelRu: string;
  trustpilotLink: string;
  telegramBot: string;
  whatsappSupport: string;
  chromeExtensionUrl: string;
  ymId: string;
};

const config: EnvConfig = {
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY || "",
  amplitudeQueryPropertyPrefix: import.meta.env.VITE_AMPLITUDE_QUERY_PROPERTY_PREFIX || "pst_",
  appUrl: import.meta.env.VITE_APP_URL || "https://pstage.net",
  apiUrl: import.meta.env.VITE_API_URL || "https://api.pstage.net",
  contentApiUrl:
    import.meta.env.VITE_CONTENT_API_URL || "https://pst-finance.ghost.io",
  contentApiKey:
    import.meta.env.VITE_CONTENT_API_KEY || "6a03c6ba03887aec51f68af9e7",
  dashboardUrl: import.meta.env.VITE_DASHBOARD_URL || "https://app.pst.net",
  telegramSupport:
    import.meta.env.VITE_TG_SUPPORT || "https://t.me/PST_Support",
  skypeSupport:
    import.meta.env.VITE_SKYPE_SUPPORT ||
    "https://join.skype.com/invite/CSLcBNTRHu6l",
  telegramChannelEn:
    import.meta.env.VITE_TG_CHANNEL_EN || "https://t.me/pstdotnet",
  telegramChannelRu:
    import.meta.env.VITE_TG_CHANNEL_RU || "https://t.me/paystoretransfer",
  twitterChannelEn:
    import.meta.env.VITE_TWITTER_CHANNEL_EN || "https://twitter.com/pstdotnet",
  twitterChannelRu:
    import.meta.env.VITE_TWITTER_CHANNEL_RU || "https://twitter.com/pstdotnet",
  trustpilotLink: "https://www.trustpilot.com/review/pst.net",
  telegramBot: "https://t.me/PayStoreTransfer_bot",
  whatsappSupport: import.meta.env.VITE_WHATSAPP_SUPPORT || "+447383194509",
  chromeExtensionUrl: import.meta.env.VITE_CHROME_EXTENSION_URL || "https://chrome.google.com/webstore/detail/crypto-cardholder-by-pstn/mkfgeifgmfnklgfhdblfeeemhkhgigee",
  ymId: import.meta.env.VITE_YM_ID || "90510038"
};

export default config;
