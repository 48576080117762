import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
// import { TextPlugin } from "gsap/TextPlugin";
// import Draggable from "gsap/Draggable";
// import Observer from "gsap/Observer";
// import { CustomEase } from "gsap/CustomEase";
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:mounted", () => {
    if (process.client) {
      gsap.registerPlugin(
        ScrollTrigger,
        ScrollSmoother
        // TextPlugin
        // Observer,
        // CustomEase
        //   Draggable
      );
    }
    ScrollTrigger.config({ ignoreMobileResize: false });
  });

  return {
    provide: {
      gsap,
      ScrollTrigger,
      ScrollSmoother,
      // TextPlugin,
      // Observer,
      // Draggable,
      // CustomEase
    },
  };
});
