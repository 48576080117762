// eslint-disable-next-line
import type { AmplitudeClient, Config } from "amplitude-js";
import amplitude from "amplitude-js";
import Env from "@/config/env";

const apiKey = Env.amplitudeApiKey;
const defaultApiConfig: Config = {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
  apiEndpoint: "api-a.pst.net/api"
};

/** */
class Amplitude {
  private static _instance: AmplitudeClient;

  public static get Instance() {
    return this._instance || (this._instance = amplitude.getInstance());
  }

  // Init
  public static init(config: Config = defaultApiConfig) {
    if (!apiKey) return;

    Amplitude.Instance.init(apiKey, undefined, config);
  }

  // Set user id
  public static setUserId(id: string) {
    if (!apiKey) return;

    Amplitude.Instance.setUserId(id);
  }

  // Set user properties from query
  public static setUserPropertyFromQuery(route: any) {
    const properties: [] = Object.entries(route.query).filter(([key]) => key.includes(Env.amplitudeQueryPropertyPrefix));
    if (!properties.length) return;
    properties.forEach(([key, value]) => {
      Amplitude.Instance.setUserProperties({ [key]: value });
    });
  }
}

export default Amplitude;
